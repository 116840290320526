import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import findLastIndex from 'lodash/findLastIndex'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import { TablePagination } from '@material-ui/core'
import 'react-table/react-table.css'
import DisplayError from '../../../fhg/components/DisplayError'
import {
    CLICK_DELAY_FOR_SELECTION,
    DATE_FORMAT,
    HEADER_HEIGHT_EVAL_TABLE,
    UP_ARROW,
    HEADER_ICON_WIDTH_EVAL_TABLE,
    DOWN_ARROW,
    DEFAULT_STATUS_ORDER,
} from '../../../Constants'
import FHGTypography from '../../../fhg/components/Typography'
import eventCapture from '../../../eventList'
import { posthogCapture } from '../../../PostHogFunction'
import PagiActions from './eval_components/pagiActions'
import fetchData from './eval_components/packages'
import getColumns from './eval_components/tabelColmns'

const requestStyles = (theme) => ({
    progress: {
        position: 'absolute',
        marginLeft: '47%',
        top: 40,
    },
})

const styles = (theme) => ({
    table: {
        maxHeight: `calc(100% - ${theme.spacing(4) + 12}px)`,
        width: '100%',
        border: 'none',
        userSelect: 'none',
        overflow: 'auto',
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
            {
                maxHeight: '100%',
                overflow: 'hidden',
                position: 'relative',
            },
        '@media print': {
            display: 'table',
        },
        '& .-sort-desc .offset.sortContent::after': {
            transform: 'translateY(-2px)',
        },
        '& .-sort-asc .offset.sortContent::after': {
            transform: 'translateY(-2px)',
        },
        '& .-sort-desc .sortContent::after': {
            content: '""',
            width: HEADER_ICON_WIDTH_EVAL_TABLE,
            height: HEADER_HEIGHT_EVAL_TABLE - 1,
            '@supports not (-ms-high-contrast: none)': {
                backgroundColor: theme.palette.button.standard.secondary,
                '-webkit-mask': `url(${DOWN_ARROW}) no-repeat`,
                mask: `url(${DOWN_ARROW}) no-repeat`,
            },
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
                {
                    opacity: 0.5,
                    background: `url(${DOWN_ARROW}) no-repeat`,
                },
            display: 'inline-block',
            verticalAlign: 'middle',
            transform: 'translateY(-4px)',
        },
        '& .-sort-asc .sortContent::after': {
            content: '""',
            width: HEADER_ICON_WIDTH_EVAL_TABLE,
            height: HEADER_HEIGHT_EVAL_TABLE - 1,
            '@supports not (-ms-high-contrast: none)': {
                backgroundColor: theme.palette.button.standard.secondary,
                '-webkit-mask': `url(${UP_ARROW}) no-repeat`,
                mask: `url(${UP_ARROW}) no-repeat`,
            },
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
                {
                    opacity: 0.5,
                    background: `url(${UP_ARROW}) no-repeat`,
                },
            display: 'inline-block',
            verticalAlign: 'middle',
            transform: 'translateY(-4px)',
        },
    },
    emptyHeight: {
        minHeight: 400,
        height: `calc(100% - ${theme.spacing(4) + 12}px)`,
    },
    progress: {
        position: 'absolute',
        marginLeft: '47%',
        top: 40,
    },
    columnTitle: {
        //paddingTop: 3,
        paddingLeft: '20px',
        color: '#4f2682 !important',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '17px',
    },
    selected: {
        backgroundColor: 'rgba(253, 198, 7, 0.5098039216) !important',
        '&:hover': {
            backgroundColor: `${theme.palette.action.selected} !important`,
        },
    },
    menuText: {
        color: theme.palette.text.secondary,
        display: 'inline-block',
    },
    notSort: {
        '&.offset.sortContent:hover::after': {
            transform: 'translateY(-2px)',
        },
        '&.sortContent:hover::after': {
            content: '""',
            width: HEADER_ICON_WIDTH_EVAL_TABLE,
            height: HEADER_HEIGHT_EVAL_TABLE - 1,
            filter: 'opacity(30%)',
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)':
                {
                    opacity: 0.3,
                },
            background: `url(${UP_ARROW}) no-repeat`,
            display: 'inline-block',
            verticalAlign: 'middle',
            transform: 'translateY(-4px)',
        },
    },
    linkStyle: {
        marginTop: -8,
        height: 'calc(100% + 16px)',
        background: 'lightgrey',
        textDecoration: 'none',
    },
    linkStyleTwo: {
        marginTop: -8,
        height: 'calc(100% + 16px)',
        background: 'lightgrey',
    },
    defaultTextStyle: {
        fontSize: `${theme.size.font.text}rem !important`,
        color: theme.palette.text.secondary, //'rgba(66,51,19,0.8)',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    defaultTextStyleTwo: {
        fontSize: `${theme.size.font.text}rem !important`,
        color: '#4f2682 !important',
        textDecoration: 'none',
        paddingLeft: '20px',
    },
    icon: {
        width: 20, // Adjust the width and height as needed
        height: 20,
        marginLeft: '20px',
        marginTop: '5px',
    },
    dot: {
        height: 8,
        width: 8,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
    },
    capture_in_progress: {
        backgroundColor: '#F8CE1C',
    },
    capture_complete: {
        backgroundColor: theme.palette.status.captureInProgress, //'#7ED321',
    },
    sent_to_ims: {
        backgroundColor: theme.palette.status.evalInProgress, //'#4A90E2',
    },
    final_review: {
        backgroundColor: '#742682',
    },
    finalized: {
        backgroundColor: theme.palette.status.finalized, //'#406370',
    },
    archived: {
        backgroundColor: theme.palette.status.archived, //'#D8D8D8',
    },
})

/**
 * The evaluator dashboard table of evaluations.
 *
 * Last Reviewed 11/12/18
 */
class EvalDashboardTable extends React.PureComponent {
    static DATE_MISSING
    blockScroll = false

    static propTypes = {
        intl: PropTypes.any.isRequired, // Localization object for localizing text.
        classes: PropTypes.object.isRequired, // The style classes for the component.
        history: PropTypes.object.isRequired, // Browser location history.
        match: PropTypes.object.isRequired, // Location matching and params information.
        search: PropTypes.string, // The search/filter text to limit rows to those containing search.
        filter: PropTypes.arrayOf(PropTypes.string), // The workflow status filter to limit rows to those with that status.
        data: PropTypes.any, // The list of evaluations.
        saveSort: PropTypes.bool, // Save the sort to session storage.
        selectedEmployees: PropTypes.array,
        employeesFilterClose: PropTypes.bool,
    }

    static defaultProps = {
        filter: DEFAULT_STATUS_ORDER,
        search: '',
        saveSort: true,
    }

    constructor(props, context) {
        super(props, context)
        // Read the table settings from local storage to restore when the user comes back to this page.
        const evalTableSortString = localStorage.getItem('evalTableSort')
        const evalTableSort = evalTableSortString
            ? JSON.parse(evalTableSortString)
            : [{ id: 'evalUpdate', desc: true }]

        // Find the last URL that isn't this page.
        const locations = props.history.getRecentLocations()
        const index =
            locations &&
            findLastIndex(locations, (item) => {
                return item.pathname !== props.match.url
            })

        //Set the selected URI to the last URL that isn't this page.
        this.state = {
            selected: undefined,
            selectedUri: index >= 0 ? locations[index].pathname : undefined,
            sort: evalTableSort,
            defaultSort: evalTableSort,
            filteredData: [],
            pagiEvalData: {},
            archivedData: [],
            pageNumber: 0,
            rowsPerPage: 10,
            isLoading: false,
            selectedEmployees: [],
            filter: [],
            resetPageNumber: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { search, filter, employeesFilterClose, selectedEmployees } =
            this.props
        // console.log("Filter props", this.props.employeesFilterClose);

        const isDataChanged = !isEqual(nextProps.data, this.props.data)

        if (!isDataChanged) {
            this.setState({
                selectedEmployees: nextProps.selectedEmployees,
                isLoading: true,
            })
            this.setState({
                filter: nextProps.filter,
                isLoading: true,
            })

            // Check if props.filter or props.selectedEmployees has changed
            if (
                nextProps.filter !== filter ||
                !isEqual(nextProps.selectedEmployees, selectedEmployees)
            ) {
                // Reset pageNumber to 1
                if (this.state.resetPageNumber) {
                    this.setState({ pageNumber: 0 }, () => {
                        this.urlUpdate()
                    })
                }
            }
            // console.log("Check if close", employeesFilterClose);

            if (!employeesFilterClose || selectedEmployees !== '') {
                this.fetchPagiEvalData()
            }
        }
    }

    // Called a function that fetches data for Catalogs / Evaluations pagination. This call doesn't apply to apply to seach or filted data
    componentDidMount() {
        const evalTableString = sessionStorage.evalTable
        const evalTable = evalTableString
            ? JSON.parse(evalTableString)
            : { sort: [{ id: 'evalUpdate', desc: true }] }

        this.setState({ isLoading: true })
        this.fetchPagiEvalData().then((data) => {
            this.setState({
                filteredData: data?.packages,
            })
        })
        const { location } = this.props
        const searchParams = new URLSearchParams(location.search)
        const page = parseInt(searchParams.get('page'), 10) || 1
        const row = parseInt(searchParams.get('rowsPerPage'), 10) || 10
        this.setState({ pageNumber: page - 1, rowsPerPage: row })
    }

    componentDidUpdate() {
        this.setState({ resetPageNumber: true })
        this.scrollIntoView()
        const { sort } = this.state
        // Save the sort to session storage
        sessionStorage.evalTable = JSON.stringify({ sort })
    }

    /**
     * Sort based on the two dates. Used to sort the date columns.
     *
     * NOTE: This is similar to the common sortDate, but uses a DATE_FORMAT.
     *
     * @param a The first date.
     * @param b The second date.
     * @return {number} 0, 1, -1 for equal, greater than, and less than respectively.
     */
    sortDate = (a, b) => {
        if (a === b) {
            return 0
        }
        if (
            a === EvalDashboardTable.DATE_MISSING ||
            b === EvalDashboardTable.DATE_MISSING
        ) {
            return a === EvalDashboardTable.DATE_MISSING ? -1 : 1
        }
        const aMoment = moment(a, DATE_FORMAT)
        const bMoment = moment(b, DATE_FORMAT)

        if (aMoment.isSame(bMoment)) {
            return 0
        }
        return aMoment.isAfter(bMoment) ? 1 : -1
    }

    /**
     * When the sort values change save them locally and in session storage.
     *
     * @param sort The new sort object from ReactTable.
     */
    onSortedChange = (sort) => {
        this.setState({ sort })
        if (this.props.saveSort) {
            localStorage.setItem('evalTableSort', JSON.stringify(sort))
        }
    }

    /**
     * Indicates if the row is selected.
     *
     * @param row The row to check if it is selected.
     * @return {boolean} True if the row is selected and false otherwise.
     */
    isRowSelected = (row) => {
        const { selected, selectedUri } = this.state

        if (row) {
            if (selected) {
                return selected === row.index
            }
            if (selectedUri) {
                if (selectedUri.indexOf('items') >= 0) {
                    if (row.original.items) {
                        const index = row.original.items.findIndex(
                            (item) => selectedUri.indexOf(item.uri) >= 0
                        )
                        return index >= 0
                    }
                } else {
                    return selectedUri.indexOf(decodeURI(row.original.uri)) >= 0
                }
            }
        }

        return false
    }

    /**
     * Scroll the selected table row into view. The selection is indicated by the 'this.props.classes.selected' class.
     */
    scrollIntoView = () => {
        if (this.blockScroll === false) {
            const elements = document.getElementsByClassName(
                this.props.classes.selected
            )
            let objDiv = document.getElementsByClassName('rt-tbody')[0]
            if (objDiv && elements.length > 0) {
                objDiv.scrollTop =
                    elements[0].offsetTop -
                    objDiv.offsetTop -
                    objDiv.offsetHeight / 2 +
                    elements[0].offsetHeight / 2
            }
        }
    }

    /**
     * Get the status value from the item using the column accessor.
     *
     * @param item The data item to get the status
     * @param columns The table columns.
     * @return {*} The status value.
     */
    getStatus = (item, columns) => {
        const statusAccessor = columns.find(
            (column) => column.id === 'evalStatus'
        ).accessor
        return get(item, statusAccessor)
    }

    getSortFromStorage = () => {
        const evalTableString = sessionStorage.evalTable
        const evalTable = evalTableString
            ? JSON.parse(evalTableString)
            : { sort: [{ id: 'evalUpdate', desc: true }] }
        return evalTable.sort
    }

    /**
     * When the user searches the table.
     *
     * @param search The search text to find.
     * @param filter The filter for the table.
     * @param columns The table columns. Used for the accessor to get the data from the column to search.
     * @param data The table data.
     * @param archivedData The table archived data.
     * @return {*|Array} The array of filtered rows.
     */

    /**
     * Request the archived evaluations from the server.
     *
     * @param newFilter The changed filters.
     * @param existingFilter The existing filters before the change.
     * @return {Promise<*>} The promise for the archived data to be returned.
     */

    fetchPagiEvalData = async () => {
        this.setState({ isLoading: true })
        const { selectedEmployees, filter, pageNumber, rowsPerPage } =
            this.state
        // console.log(
        //   "THIS PAGE FOR TEST",
        //   this.state.pageNumber,
        //   this.state.rowsPerPage
        // );
        const data = await fetchData({
            selectedEmployees,
            filter,
            pageNumber,
            rowsPerPage,
        })

        this.setState({
            pagiEvalData: data,
            filteredData: data.packages,
            isLoading: false,
        })

        return data
    }

    handleChangePage = (event, newPage) => {
        this.setState({ pageNumber: newPage, isLoading: true }, () => {
            this.fetchPagiEvalData()
            const { history, match } = this.props
            const { rowsPerPage } = this.state
            history.push(
                `${match.url}?page=${newPage + 1}&rowsPerPage=${rowsPerPage}`
            )
        })
    }

    urlUpdate = () => {
        const { history, match } = this.props
        const { pageNumber, rowsPerPage } = this.state
        history.push(
            `${match.url}?page=${pageNumber + 1}&rowsPerPage=${rowsPerPage}`
        )
    }

    // Handles changes number of eveluations or catalogs per page

    handleChangeRowsPerPage = (event) => {
        this.setState(
            {
                rowsPerPage: parseInt(event.target.value, 10),
                pageNumber: 0,
                isLoading: true,
            },
            () => {
                this.fetchPagiEvalData()
                this.urlUpdate()
                localStorage.setItem(
                    'rowsPerPage',
                    this.state.rowsPerPage.toString()
                )
            }
        )
    }

    handleRowClick = (row, event) => {
        if (row.original && row.original.uri) {
            window.open(row.original.uri, '_blank')
        }
    }

    /**
     * Get the table columns for the evaluations table. Used for the evaluations table, but also used for the filtering
     * and searches to access the column data.
     *
     * @param defaultSort The sort to initialize the table with. Used only in the constructor before the state is set.
     * @return {*[]} The array of evaluation table columns.
     */

    static getEvalDate(row) {
        if (row.evaluation && row.evaluation.created) {
            return moment(row.evaluation.created).format(DATE_FORMAT)
        } else {
            return EvalDashboardTable.DATE_MISSING
        }
    }

    static getEvalUpdate(row) {
        if (row.updated) {
            return moment(row.updated).format(DATE_FORMAT)
        } else {
            return EvalDashboardTable.DATE_MISSING
        }
    }

    render() {
        const {
            defaultSort,
            pagiEvalData,
            errorId,
            error,
            showError,
            isLoading,
            pageNumber,
            rowsPerPage,
        } = this.state
        const { classes, history, search } = this.props

        const columns = getColumns(defaultSort, this.props, this.state)

        return (
            <Fragment>
                {showError && (
                    <DisplayError
                        error={error}
                        errorId={errorId}
                        enableRefresh={
                            errorId !== 'evalDashboard.consensus.error'
                        }
                    />
                )}
                <ReactTable
                    defaultPageSize={10000}
                    minRows={1}
                    loading={isLoading}
                    LoadingComponent={() =>
                        isLoading && (
                            <CircularProgress className={classes.progress} />
                        )
                    }
                    multiSort={false}
                    className={classNames(
                        classes.table,
                        '-highlight',
                        'eval-table',
                        {
                            [classes.emptyHeight]:
                                !this.state.filteredData ||
                                this.state.filteredData.length <= 0,
                        }
                    )}
                    data={this.state.filteredData}
                    defaultSorted={defaultSort}
                    onSortedChange={this.onSortedChange}
                    showPagination={false}
                    noDataText={
                        isLoading ? (
                            ''
                        ) : (
                            <FHGTypography
                                id={
                                    search
                                        ? 'search.noCatalogsFound.text'
                                        : undefined
                                }
                            />
                        )
                    }
                    getTrProps={(state, rowInfo) => {
                        return {
                            className: this.isRowSelected(rowInfo)
                                ? classes.selected
                                : undefined,
                            onClick: () => {
                                console.log(
                                    'Eval data from home:',
                                    rowInfo.original
                                )
                                localStorage.setItem(
                                    'evaluation',
                                    JSON.stringify(rowInfo.original)
                                )
                                this.handleRowClick(rowInfo)
                                if (!this.blockScroll) {
                                    EvalDashboardTable.lastSelectedIndex =
                                        rowInfo.index
                                    this.blockScroll = true
                                    posthogCapture(
                                        eventCapture.EQUIPMENT_LIST_VIEW
                                    )
                                    this.setState(
                                        { selected: rowInfo.index },
                                        () => {
                                            window.setTimeout(() => {
                                                this.blockScroll = false
                                            }, CLICK_DELAY_FOR_SELECTION)
                                        }
                                    )
                                }
                            },
                        }
                    }}
                    columns={columns}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TablePagination
                        component="div"
                        count={pagiEvalData?.total_rows ?? 0}
                        page={pageNumber}
                        rowsPerPage={rowsPerPage}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        labelRowsPerPage="Catalogs per page:"
                        rowsPerPageOptions={[
                            5,
                            10,
                            15,
                            25,
                            35,
                            50,
                            100,
                            { label: 'All', value: pagiEvalData.total_rows },
                        ]}
                        ActionsComponent={PagiActions}
                    />
                </div>
            </Fragment>
        )
    }
}

export default React.memo(
    withStyles(requestStyles)(
        withRouter(injectIntl(withStyles(styles)(EvalDashboardTable)))
    )
)
