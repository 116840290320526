import React, { useState, useEffect, createContext, useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useIMSForm } from "../detail/IMSFormContext";
import { Auth } from "aws-amplify";
import { isAdminOnly, isMultipleOrganization } from "../../../Utils/ServerUtil";
import { FormHelperText } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";

const upcomingAuctions = [
  {
    auction_id: 11207,
    auction: "241002",
    title: "Wednesday October 02 Vehicles and Equipment Auction",
    auction_date: "241002",
    workspace_label: "241002ve",
  },
  {
    auction_id: 11208,
    auction: "241003",
    title: "Thursday October 03 Construction Equipment Auction",
    auction_date: "241003",
    workspace_label: "241003const",
  },
  {
    auction_id: 11209,
    auction: "241008",
    title: "Tuesday October 08 Government Auction",
    auction_date: "241008",
    workspace_label: "241008govt",
  },
  {
    auction_id: 11210,
    auction: "241009",
    title: "Wednesday October 09 Ag Equipment Auction",
    auction_date: "241009",
    workspace_label: "241009ag",
  },
  {
    auction_id: 11211,
    auction: "241010",
    title: "Thursday October 10 Truck and Trailer Auction",
    auction_date: "241010",
    workspace_label: "241010truck",
  },
  {
    auction_id: 11243,
    auction: "241015A",
    title: "Tuesday October 15 Kansas Turnpike Authority Auction",
    auction_date: "241015",
    workspace_label: "241015kta",
  },
  {
    auction_id: 11239,
    auction: "241015",
    title: "Tuesday October 15 Precision Excavating Retirement Auction",
    auction_date: "241015",
    workspace_label: "241015precision",
  },
  {
    auction_id: 11212,
    auction: "241016",
    title: "Wednesday October 16 Vehicles and Equipment Auction",
    auction_date: "241016",
    workspace_label: "241016ve",
  },
  {
    auction_id: 11213,
    auction: "241017",
    title: "Thursday October 17 Construction Equipment Auction",
    auction_date: "241017",
    workspace_label: "241017const",
  },
  {
    auction_id: 11214,
    auction: "241022",
    title: "Tuesday October 22 Government Auction",
    auction_date: "241022",
    workspace_label: "241022govt",
  },
  {
    auction_id: 11215,
    auction: "241023",
    title: "Wednesday October 23 Ag Equipment Auction",
    auction_date: "241023",
    workspace_label: "241023ag",
  },
  {
    auction_id: 11216,
    auction: "241024",
    title: "Thursday October 24 Truck and Trailer Auction",
    auction_date: "241024",
    workspace_label: "241024truck",
  },
  {
    auction_id: 11245,
    auction: "241029",
    title: "Tuesday October 29 Kansas Department of Transportation Auction",
    auction_date: "241029",
    workspace_label: "241029kdot",
  },
  {
    auction_id: 11217,
    auction: "241030",
    title: "Wednesday October 30 Vehicles and Equipment Auction",
    auction_date: "241030",
    workspace_label: "241030ve",
  },
  {
    auction_id: 11218,
    auction: "241031",
    title: "Thursday October 31 Construction Equipment Auction",
    auction_date: "241031",
    workspace_label: "241031const",
  },
  {
    auction_id: 11219,
    auction: "241105",
    title: "Tuesday November 05 Government Auction",
    auction_date: "241105",
    workspace_label: "241105govt",
  },
  {
    auction_id: 11220,
    auction: "241106",
    title: "Wednesday November 06 Ag Equipment Auction",
    auction_date: "241106",
    workspace_label: "241106ag",
  },
  {
    auction_id: 11221,
    auction: "241107",
    title: "Thursday November 07 Truck and Trailer Auction",
    auction_date: "241107",
    workspace_label: "241107truck",
  },
  {
    auction_id: 11222,
    auction: "241113",
    title: "Wednesday November 13 Vehicles and Equipment Auction",
    auction_date: "241113",
    workspace_label: "241113ve",
  },
  {
    auction_id: 11223,
    auction: "241114",
    title: "Thursday November 14 Construction Equipment Auction",
    auction_date: "241114",
    workspace_label: "241114const",
  },
  {
    auction_id: 11224,
    auction: "241119",
    title: "Tuesday November 19 Government Auction",
    auction_date: "241119",
    workspace_label: "241119govt",
  },
  {
    auction_id: 11225,
    auction: "241120",
    title: "Wednesday November 20 Vehicles and Equipment Auction",
    auction_date: "241120",
    workspace_label: "241120ve",
  },
  {
    auction_id: 11246,
    auction: "241120A",
    title: "Wednesday November 20 Ag Equipment Auction",
    auction_date: "241120",
    workspace_label: "241120ag",
  },
  {
    auction_id: 11226,
    auction: "241121",
    title: "Thursday November 21 Construction Equipment Auction",
    auction_date: "241121",
    workspace_label: "241121const",
  },
  {
    auction_id: 11247,
    auction: "241121A",
    title: "Thursday November 21 Truck and Trailer Auction",
    auction_date: "241121",
    workspace_label: "241121truck",
  },
  {
    auction_id: 11227,
    auction: "241203",
    title: "Tuesday December 03 Government Auction",
    auction_date: "241203",
    workspace_label: "241203govt",
  },
  {
    auction_id: 11228,
    auction: "241204",
    title: "Wednesday December 04 Vehicles and Equipment Auction",
    auction_date: "241204",
    workspace_label: "241204ve",
  },
  {
    auction_id: 11229,
    auction: "241205",
    title: "Thursday December 05 Construction Equipment Auction",
    auction_date: "241205",
    workspace_label: "241205const",
  },
  {
    auction_id: 11230,
    auction: "241211",
    title: "Wednesday December 11 Ag Equipment Auction",
    auction_date: "241211",
    workspace_label: "241211ag",
  },
  {
    auction_id: 11231,
    auction: "241212",
    title: "Thursday December 12 Truck and Trailer Auction",
    auction_date: "241212",
    workspace_label: "241212truck",
  },
  {
    auction_id: 11232,
    auction: "241217",
    title: "Tuesday December 17 Government Auction",
    auction_date: "241217",
    workspace_label: "241217govt",
  },
  {
    auction_id: 11233,
    auction: "241218",
    title: "Wednesday December 18 Vehicles and Equipment Auction",
    auction_date: "241218",
    workspace_label: "241218ve",
  },
  {
    auction_id: 11234,
    auction: "241219",
    title: "Thursday December 19 Construction Equipment Auction",
    auction_date: "241219",
    workspace_label: "241219const",
  },
  {
    auction_id: 11235,
    auction: "241230",
    title: "Monday December 30 Vehicles and Equipment Auction",
    auction_date: "241230",
    workspace_label: "241230ve",
  },
  {
    auction_id: 11236,
    auction: "241230A",
    title: "Monday December 30 Ag Equipment Auction",
    auction_date: "241230",
    workspace_label: "241230ag",
  },
  {
    auction_id: 11237,
    auction: "241231",
    title: "Tuesday December 31 Construction Equipment Auction",
    auction_date: "241231",
    workspace_label: "241231const",
  },
  {
    auction_id: 11238,
    auction: "241231A",
    title: "Tuesday December 31 Truck and Trailer Auction",
    auction_date: "241231",
    workspace_label: "241231truck",
  },
];

// Step 1: Create a context
export const IMSContext = createContext();

IMSformCategtorySelect.propTypes = {
  customerNumber: PropTypes.string,
};

function IMSformCategtorySelect({ customerNumber, evalItem }) {
  // Step 2: Use state from the context
  const {
    categoriesData,
    setCategoriesData,
    industriesData,
    setIndustriesData,
    selectedIndustry,
    setSelectedIndustry,
    selectedFamily,
    setSelectedFamily,
    selectedCategory,
    setSelectedCategory,
    setSelectedOpportunity,
    estimatedHammer,
    setEstimatedHammer,
    jobCodeData,
    setJobCodeData,
    auctionsJobCodeData,
    setAuctionsJobCodeData,
  } = useContext(IMSContext);

  const { imsForm, setImsForm } = useIMSForm();
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState("none"); // 'none', 'mock', or 'api'
  const [lastValidJobCode, setLastValidJobCode] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // useEffect(() => {
  //   console.log("TESTING REAL FORM ====================>>>>>>>>", imsForm);
  // }, [selectedCategory]);

  const imsFormData = async () => {
    try {
      const response = await fetch(
        "https://ims-api.purplewave.com/v1/industries?published=1"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setIndustriesData(data);

      const allCategories = data.flatMap((industry) =>
        industry.Families.flatMap((family) =>
          family.Categories.map((category) => category.category)
        )
      );
      setCategoriesData(allCategories);

      console.log("ALL CATEGORIES", allCategories);
    } catch (error) {
      console.error("Error fetching industries:", error);
    }
  };

  // useEffect(() => {
  //   imsFormData();
  //   auctionsData();
  // }, []);

  // Separate useEffect for initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      await Promise.all([imsFormData(), auctionsData()]);
      setIsLoading(false);
    };

    loadInitialData();
  }, []);

  // Separate useEffect for handling job code initialization
  useEffect(() => {
    if (!isInitialized || !imsForm?.item?.ims_forms_data?.jobcode) return;

    const jobcodeValue = imsForm.item.ims_forms_data.jobcode;
    console.log(
      "Checking job code:",
      jobcodeValue,
      "with data source:",
      dataSource
    );

    // Keep the current job code if it was previously validated
    if (jobcodeValue === lastValidJobCode) {
      console.log("Using previously validated job code:", jobcodeValue);
      return;
    }

    // Check current data source
    const currentData =
      auctionsJobCodeData.length > 0 ? auctionsJobCodeData : upcomingAuctions;
    const isValidJobCode = currentData.some(
      (auction) => auction.workspace_label === jobcodeValue
    );

    if (isValidJobCode) {
      console.log("Valid job code found in", dataSource, "data:", jobcodeValue);
      setJobCodeData(jobcodeValue);
      setLastValidJobCode(jobcodeValue);
    } else if (dataSource === "api") {
      // If not found in API data, check mock data
      const isValidMockJobCode = upcomingAuctions.some(
        (auction) => auction.workspace_label === jobcodeValue
      );

      if (isValidMockJobCode) {
        console.log("Valid job code found in mock data:", jobcodeValue);
        setJobCodeData(jobcodeValue);
        setLastValidJobCode(jobcodeValue);
        // Keep using mock data since we found a valid job code there
        setAuctionsJobCodeData(upcomingAuctions);
        setDataSource("mock");
      }
    }
  }, [isInitialized, imsForm, dataSource, auctionsJobCodeData]);

  // Separate useEffect for estimated hammer
  useEffect(() => {
    if (imsForm?.item?.ims_forms_data?.capture_estimate) {
      console.log(
        "Setting Hammer:",
        imsForm.item.ims_forms_data.capture_estimate
      );
      setEstimatedHammer(imsForm.item.ims_forms_data.capture_estimate);
    }
  }, [imsForm]);

  useEffect(() => {
    // Check if evalItem.item.ims_form_data.opportunity_id exists
    const opportunityId = imsForm?.item?.ims_forms_data?.opportunity_id
      ? imsForm?.item?.ims_forms_data?.opportunity_id
      : evalItem?.item?.ims_forms_data?.opportunity_id;
    // If opportunityId exists, set the form to opportunityId, else set it to null
    setSelectedOpportunity(opportunityId ? opportunityId : null);
  }, [evalItem, imsForm]);

  const industryOptions = industriesData.map((industry) => ({
    value: industry,
    label: industry.industry,
  }));

  const familyOptions = selectedIndustry
    ? selectedIndustry.Families.map((family) => ({
        value: family,
        label: family.family,
      }))
    : [];

  const categoryOptions = selectedFamily
    ? selectedFamily.Categories.map((category) => ({
        value: category,
        label: category.category,
      }))
    : [];

  const handleIndustryChange = (event) => {
    const value = event.target.value;
    setSelectedIndustry(value);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  const handleFamilyChange = (event) => {
    const value = event.target.value;
    setSelectedFamily(value);
    setSelectedCategory(null);
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const handleAutocompleteChange = (_, newValue) => {
    const selectedCategory = categoriesData.find(
      (category) => category === newValue
    );
    console.log("Selected Category:", selectedCategory); // Debug log

    if (selectedCategory) {
      // Find the corresponding industry, family, and category
      for (const industry of industriesData) {
        for (const family of industry.Families) {
          for (const category of family.Categories) {
            if (category.category === newValue) {
              console.log("Select Category in search:", selectedCategory);
              setSelectedIndustry(industry);
              setSelectedFamily(family);
              setSelectedCategory(category);
              return;
            }
          }
        }
      }
    }

    // If no matching industry, family, and category found, reset the selections
    setSelectedIndustry(null);
    setSelectedFamily(null);
    setSelectedCategory(null);
  };

  useEffect(() => {
    //console.log("RELOAD=======RELOAD==========RELOAD", imsForm, evalItem, selectedCategory);

    const selectedCategoryFromIMSform = imsForm?.item?.ims_forms_data.category;
    const selectedCategoryFromEvalItem =
      evalItem?.item?.ims_forms_data.category;

    console.log(
      "Selected category from IMS form:",
      selectedCategoryFromIMSform
    );
    console.log(
      "Selected category from Eval item:",
      selectedCategoryFromEvalItem
    );

    if (selectedCategoryFromIMSform) {
      const newValue = imsForm?.item.ims_forms_data.category;
      handleAutocompleteChange(null, newValue);
    } else if (selectedCategoryFromEvalItem) {
      const newValue = evalItem?.item?.ims_forms_data.category;
      handleAutocompleteChange(null, newValue);
    } else if (!selectedCategory) {
      console.log("Selected category is null. Skipping effect.");
    }
  }, [imsForm, evalItem, categoriesData]);

  const auctionsData = async () => {
    try {
      let jwtToken;
      try {
        const session = await Auth.currentSession();
        jwtToken = session.idToken.jwtToken;
      } catch (e) {
        console.log("Auth error, using mock data");
        setAuctionsJobCodeData(upcomingAuctions);
        setDataSource("mock");
        return;
      }

      const headers = {
        Authorization: jwtToken,
      };

      if (isAdminOnly() || isMultipleOrganization()) {
        const organization = localStorage["hw." + window.btoa("organization")];
        if (organization && window.atob(organization)) {
          headers["HW-Organization"] = window.atob(organization);
        }
      }

      const response = await fetch(
        "https://dashboard.purplewave.com/v3/leads/upcomingauctions",
        {
          method: "GET",
          headers: headers,
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.upcomingAuctions?.length > 0) {
          console.log("Setting API data");
          setAuctionsJobCodeData(data.upcomingAuctions);
          setDataSource("api");
        } else {
          console.log("API returned empty data, using mock data");
          setAuctionsJobCodeData(upcomingAuctions);
          setDataSource("mock");
        }
      }
    } catch (error) {
      console.error("Error fetching auctions:", error);
      console.log("Error occurred, using mock data");
      setAuctionsJobCodeData(upcomingAuctions);
      setDataSource("mock");
    } finally {
      setIsInitialized(true);
    }
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        id="category-search"
        options={categoriesData}
        value={selectedCategory ? selectedCategory.category : null}
        sx={{ width: "60%" }}
        onChange={(_, newValue) => handleAutocompleteChange(_, newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Search Category" />
        )}
      />
      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedIndustry}
          htmlFor="industry-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Optional: adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Industry
        </InputLabel>
        <Select
          value={selectedIndustry}
          onChange={handleIndustryChange}
          label="Select Industry"
          inputProps={{
            name: "industry",
            id: "industry-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {industryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedFamily}
          htmlFor="family-select"
          style={{
            backgroundColor: "#ffffff", // Match the background color of the form
            padding: "0 4px", // Adds padding to avoid label overlap with border
            zIndex: 1, // Ensure label is on top of the border
          }}
        >
          Select Equipment Type
        </InputLabel>
        <Select
          value={selectedFamily}
          onChange={handleFamilyChange}
          label="Select Equipment Type"
          inputProps={{
            name: "family",
            id: "family-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {familyOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth>
        <InputLabel
          shrink={!!selectedCategory}
          htmlFor="category-select"
          style={{
            backgroundColor: "#ffffff",
            padding: "0 4px",
            zIndex: 1,
            fontWeight: "400 !important",
          }}
        >
          Select Category
        </InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          label="Select Category"
          inputProps={{
            name: "category",
            id: "category-select",
          }}
          style={{
            width: "60%",
            fontWeight: "normal",
            fontWeight: "400 !important",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                zIndex: 2, // Ensure dropdown options appear above everything
              },
            },
          }}
        >
          {categoryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ margin: "12px 0" }}></div>
      <FormControl fullWidth >
        <InputLabel
          id="jobcode-select-label"
          shrink={!isInitialized || !!jobCodeData}
          htmlFor="jobcode-select"
          style={{
            backgroundColor: "#ffffff",
            padding: "0 4px",
            zIndex: 1,
          }}
        >
          {!isInitialized ? "Loading Job Codes..." : "Select Job Code"}
        </InputLabel>
        <Select
          labelId="jobcode-select-label"
          value={jobCodeData || ""}
          onChange={(event) => {
            const newValue = event.target.value;
            console.log("Selected new job code:", newValue);
            setJobCodeData(newValue);
            setLastValidJobCode(newValue);
          }}
          label="Select Job Code"
          inputProps={{
            name: "jobcode",
            id: "jobcode-select",
          }}
          style={{ width: "60%", fontWeight: "normal" }}
          disabled={!isInitialized}
        >
          {auctionsJobCodeData.map((jobcode) => (
            <MenuItem key={jobcode.auction_id} value={jobcode.workspace_label}>
              {jobcode.workspace_label}
            </MenuItem>
          ))}
        </Select>
        {!jobCodeData && isInitialized && (
          <FormHelperText style={{color: 'red'}}>
            Form submission will fail if job code is not provided
          </FormHelperText>
        )}
      </FormControl>

      <div style={{ margin: "12px 0" }}></div>

      <FormControl fullWidth error={!estimatedHammer}>
        <TextField
          label="Estimated Hammer"
          value={estimatedHammer}
          onChange={(event) => {
            const value = event.target.value.replace(/[^0-9]/g, "");
            setEstimatedHammer(value);
          }}
          InputProps={{
            name: "estimatedHammer",
            id: "estimatedHammer-select",
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          style={{ width: "60%", fontWeight: "normal" }}
          variant="outlined"
        />
        {!estimatedHammer && (
          <FormHelperText style={{color: 'red'}}>
            Form submission will fail if Estimated Hammer is not provided
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export const IMSProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [industriesData, setIndustriesData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOpportunity, setSelectedOpportunity] = useState("");
  const [jobCodeData, setJobCodeData] = useState("");
  const [estimatedHammer, setEstimatedHammer] = useState("");
  const [auctionsJobCodeData, setAuctionsJobCodeData] = useState([]);

  useEffect(() => {
    // Your useEffect logic without localStorage
  }, []);

  return (
    <IMSContext.Provider
      value={{
        categoriesData,
        setCategoriesData,
        industriesData,
        setIndustriesData,
        selectedIndustry,
        setSelectedIndustry,
        selectedFamily,
        setSelectedFamily,
        selectedCategory,
        setSelectedCategory,
        selectedOpportunity,
        setSelectedOpportunity,
        jobCodeData,
        setJobCodeData,
        auctionsJobCodeData,
        setAuctionsJobCodeData,
        estimatedHammer,
        setEstimatedHammer,
      }}
    >
      {children}
    </IMSContext.Provider>
  );
};

export default IMSformCategtorySelect;
